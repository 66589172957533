<template>
    <CSDialog
            id="batchTurnOnCheck-dialog"
            dialog-title="批量修改检测"
            dialog-width="620px"
            :dialog-visible="batchTurnOnCheckVisible"
            @onClose="batchTurnOnCheckVisible = false"
            @onConfirm="saveCheck()"
    >
        <template v-slot:dialog-content>
            <div style="padding:30px;font-size:24px">
                <span>已选 {{ cameraIds.length }} 个监控</span>
                <div>
                    <label>
                        <input v-model="isAttention" type="checkbox">
                        <span>关注人检测</span>
                    </label>
                    <span v-show="isAttention" style="margin-left:40px">
                        显示最近
                        <CSSelect style="vertical-align: top; margin: 0 10px;" iWidth="36px" height="40px">
                            <select v-model="attentionDate" style="width: 120px;color: #999999">
                                <option value="">请选择</option>
                                <option
                                        v-for="day in timeList"
                                        :key="day"
                                        :value="day"
                                >
                                    {{day}}
                                </option>
                            </select>
                        </CSSelect>
                        数据
                    </span>
                </div>
                <!--                <div>
                                    <label>
                                        <input v-model="isPeopleCounting" type="checkbox">
                                        <span>人流统计监测</span>
                                    </label>
                                    <span  v-show="isPeopleCounting"
                                        style="margin-left:40px"
                                    >
                                        显示最近
                                        <CSSelect style="vertical-align: top; margin: 0 10px;" iWidth="36px" height="40px">
                                            <select v-model="peopleCountingDate" style="width: 120px">
                                                <option value="">请选择</option>
                                                <option
                                                    v-for="day in timeList"
                                                    :key="day"
                                                    :value="day"
                                                >
                                                    {{day}}
                                </option>
                            </select>
                        </CSSelect>
                        数据
                    </span>
                </div>-->
                <div>
                    <label>
                        <input v-model="isIllegalParking" type="checkbox">
                        <span>汽车违停监测</span>
                    </label>
                    <span v-show="isIllegalParking"
                          style="margin-left:40px"
                    >
                                        显示最近
                                        <CSSelect style="vertical-align: top; margin: 0 10px;" iWidth="36px"
                                                  height="40px">
                                            <select v-model="illegalParkingDate" style="width: 120px;color: #999999">
                                                <option value="">请选择</option>
                                                <option
                                                        v-for="day in timeList"
                                                        :key="day"
                                                        :value="day"
                                                >
                                                    {{day}}
                                </option>
                            </select>
                        </CSSelect>
                        数据
                    </span>
                </div>
                <div>
                    <label>
                        <input v-model="isNoMask" type="checkbox">
                        <span>未戴口罩检测</span>
                    </label>
                    <span v-show="isNoMask"
                          style="margin-left:40px"
                    >
                        显示最近
                        <CSSelect style="vertical-align: top; margin: 0 10px;" iWidth="36px" height="40px">
                            <select v-model="noMaskDate" style="width: 120px;color: #999999">
                                <option value="">请选择</option>
                                <option
                                        v-for="day in timeList"
                                        :key="day"
                                        :value="day"
                                >
                                    {{day}}
                                </option>
                            </select>
                        </CSSelect>
                        数据
                    </span>
                </div>
                <div>
                    <label>
                        <input v-model="isSmoke" type="checkbox">
                        <span>吸烟检测</span>
                    </label>
                    <span v-show="isSmoke"
                          style="margin-left:40px"
                    >
                        显示最近
                        <CSSelect style="vertical-align: top; margin: 0 10px;" iWidth="36px" height="40px">
                            <select v-model="smokeDate" style="width: 120px;color: #999999">
                                <option value="">请选择</option>
                                <option
                                        v-for="day in timeList"
                                        :key="day"
                                        :value="day"
                                >
                                    {{day}}
                                </option>
                            </select>
                        </CSSelect>
                        数据
                    </span>
                </div>
                <div>
                    <label>
                        <input v-model="isFall" type="checkbox">
                        <span>跌倒检测</span>
                    </label>
                    <span v-show="isFall"
                          style="margin-left:40px"
                    >
                        显示最近
                        <CSSelect style="vertical-align: top; margin: 0 10px;" iWidth="36px" height="40px">
                            <select v-model="fallDate" style="width: 120px;color: #999999">
                                <option value="">请选择</option>
                                <option
                                        v-for="day in timeList"
                                        :key="day"
                                        :value="day"
                                >
                                    {{day}}
                                </option>
                            </select>
                        </CSSelect>
                        数据
                    </span>
                </div>
                <!--                <div>
                                    <label>
                                        <input v-model="isObstructView" type="checkbox">
                                        <span>监控遮挡脸检测</span>
                                    </label>
                                    <span  v-show="isObstructView"
                                        style="margin-left:40px"
                                    >
                                        显示最近
                                        <CSSelect style="vertical-align: top; margin: 0 10px;" iWidth="36px" height="40px">
                                            <select v-model="obstructViewDate" style="width: 120px">
                                               <option value="">请选择</option>
                                                <option
                                                    v-for="day in timeList"
                                                    :key="day"
                                                    :value="day"
                                                >
                                                    {{day}}
                                </option>
                            </select>
                        </CSSelect>
                        数据
                    </span>
                </div>-->
                <br/>
                <!--                <span style="font-size:20px;color:#999;display:inline-block;margin-top:15px;">注：“人员聚集”、“区域入侵”检测，需单独设置。保存后，所选监控将会统一启用相同的检测。</span>-->
            </div>
        </template>
    </CSDialog>
</template>

<script>
    import CSDialog from "@/components/common/CSDialog";
    import CSSelect from "@/components/common/CSSelect";
    import {enableDetectionUrl} from "@/requestUrl";

    export default {
        components: {
            CSDialog,
            CSSelect,
        },
        data() {
            return {
                cameraIds: [],
                batchTurnOnCheckVisible: false,
                isAttention: 1, //是否启用关注人检测：0不启用，1启用
                isFall: 1, //是否启用跌倒检测：0不启用，1启用
                isIllegalParking: 1, //是否启用汽车违停检测：0不启用，1启用
                isNoMask: 1, //是否启用未戴口罩检测：0不启用，1启用
                isObstructView: 1, //是否启用监控遮挡检测：0不启用，1启用
                isPeopleCounting: 1, //是否启用人流统计检测：0不启用，1启用
                isSmoke: 1, //是否启用吸烟检测：0不启用，1启用
                noMaskDate: '', //未戴口罩检测显示近几天数据
                obstructViewDate: '', //监控遮挡检测显示近几天数据
                peopleCountingDate: '', //人流统计检测显示近几天数据
                smokeDate: '',              //吸烟检测显示近几天数据
                attentionDate: '', //关注人检测显示近几天数据
                fallDate: '', //跌倒检测显示近几天数据
                illegalParkingDate: '',     //汽车违停检测显示近几天数据
                timeList: [3, 7, 15, 30, 45, 60, 90],
            }
        },
        created() {
            this.$vc.on(this.$route.path, "cameraManage", "batchTurnOnCheck", ({visible, ids}) => {
                this.batchTurnOnCheckVisible = visible;
                this.cameraIds = ids;
            })
            this.$vc.on(this.$route.path, "batchTurnOffCheck", "checkDemand", (data) => {
                this.checkDemand = data;
            })
        },
        methods: {
            saveCheck() {
                const {
                    isAttention,
                    isFall,
                    isIllegalParking,
                    isNoMask,
                    isObstructView,
                    isPeopleCounting,
                    isSmoke,
                    noMaskDate,
                    obstructViewDate,
                    peopleCountingDate,
                    smokeDate,
                    attentionDate,
                    fallDate,
                    illegalParkingDate
                } = this;
                const params = {
                    isAttention,
                    isFall,
                    isIllegalParking,
                    isNoMask,
                    isObstructView,
                    isPeopleCounting,
                    isSmoke,
                    illegalParkingDate,
                };
                Object.entries(params).forEach(([key, val]) => {
                    params[key] = Number(val);
                })
                this.$fly.post(enableDetectionUrl, {
                    ids: this.cameraIds,
                    ...params,
                    noMaskDate,
                    obstructViewDate,
                    peopleCountingDate,
                    smokeDate,
                    attentionDate,
                    fallDate,
                })
                    .then(res => {
                        if (res.code !== 0) {
                            return;
                        }
                        this.batchTurnOnCheckVisible = false;
                        this.$vc.toast("保存成功")
                    })

            },
        }
    }
</script>

<style scoped>
    #batchTurnOnCheck-dialog label {
        display: block;
        padding: 10px 0px;
        margin: 0px;
    }

    #batchTurnOnCheck-dialog input {
        margin-right: 20px;
        top: 2px;
    }
</style>
