<template>
    <div id="addCameraClub">
        <CSDialog
            :dialog-title="`${originCameraGroupId !== null ? '修改' : '添加'}监控组`"
            dialog-width="940px"
            class="tenant-detail-dialog"
            :dialog-visible="addCameraClubVisible"
            @onClose="addCameraClubVisible = false;
                    checkedCameraList = []"
            @onConfirm="addCameraClub()"
        >
            <template
                v-slot:dialog-content
            >
                <div class="preview-tenant">
                    <div class="field">
                        <span class="field-label">监控组名称</span>
                        <span class="field-content">
                            <input type="text"
                                style="
                                    width:400px;
                                    height:40px;
                                    border:1px solid #979797;
                                    border-radius:4px;
                                    padding:0px 10px;
                                "
                                placeholder="限20个字" maxlength="20"
                                v-model="newCameraClub.cameraClubName"
                            />
                        </span>
                    </div>
                    <div class="field" style="margin-bottom:0px">
                        <span class="field-label">监控</span>
                        <div class="textarea-view">
                            <template v-if="checkedCameraList.length > 0">
                                <p v-for="(camera, checkedIndex) in checkedCameraList" :key="checkedIndex">
                                    {{camera.name}}
                                    <svg aria-hidden="true" class="icon" style="margin-left: 10px;"
                                         @click="deleteCheckedCamera(checkedIndex)"
                                    >
                                        <use xlink:href="#icon-menushanchu"></use>
                                    </svg>
                                </p>
                            </template>
                            <p v-else style="color: #999;">待添加</p>

                        </div>
                    </div>
                    <button
                        type="button"
                        class="btn btn-primary"
                        style="margin:20px 0px 30px 160px;font-size: 24px; line-height: 1; padding: 7px 12px;"
                        @click="opeSelectCameraDialog"
                    >
                        <svg class="icon" aria-hidden="true">
                            <use xlink:href="#icon-menujiahao"></use>
                        </svg>
                        <span style="margin-left: 10px;">添加</span>
                    </button>
                    <div class="field">
                        <span class="field-label">备注</span>
                        <textarea
                            style="
                                resize:none;
                                padding:10px;
                                width:720px;
                                height:200px;
                                overflow-y:auto;
                                background-color:#F0F0F0;
                                border-radius:4px;
                                border:0px;
                            "
                            maxlength="50"
                            placeholder="限50个字"
                            v-model="newCameraClub.cameraNote"
                        ></textarea>
                    </div>
                </div>
            </template>
        </CSDialog>
        <CSDialog
            dialog-title="选择监控"
            dialog-width="640px"
            :dialog-visible="addCameraVisible"
            @onClose="addCameraVisible = false"
            @onConfirm="addCamera()"
        >
            <template
                v-slot:dialog-content
            >
                <div style="padding:20px 30px;font-size:24px;height:600px;overflow-y:scroll">
                    <p>
                        <input style="
                            width:200px;
                            height:30px;
                            border:1px solid #979797;
                            border-radius:4px;
                            font-size:14px;
                            margin-right:10px;
                            padding: 0 5px;
                            vertical-align:super;
                        "
                        placeholder="搜索监控编号/位置"
                        type="text"
                         v-model="queryCameraParams.search"
                        class="cs-input"/>
                        <button
                            type="button"
                            class="btn btn-primary btn-sm"
                            style="vertical-align:top"
                            @click="searchCamera()"
                        >
                            查询
                        </button>
                    </p>
                    <table class="table modal-inner-table">
                        <thead>
                            <tr>
                                <th>
                                    <input type="checkBox" @click="checkedTempAllCamera"
                                       style="margin-right: 10px;" class="white"
                                      :checked="cameraList.length === tempCheckedCameraList.length"
                                    />
                                    <span>全选</span>
                                </th>
                                <th>监控编号</th>
                                <th>位置</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in cameraList" :key="item.id">
                                <td>
                                  <input type="checkbox" v-model="tempCheckedCameraList" :value="item">
                                </td>
                                <td>{{item.name}}</td>
                                <td>{{item.location}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </template>
        </CSDialog>
    </div>
</template>

<script>
import CSDialog from "@/components/common/CSDialog"
import {addCameraGroupUrl, editCameraGroupUrl, queryCameraListUrl} from "@/requestUrl";
export default {
    name:'addCameraClub',
    components:{
        CSDialog,
    },
    data(){
        return {
            originCameraGroupId: null,
            addCameraClubVisible: false,
            addCameraVisible: false,
            // 选中的监控列表
            checkedCameraList: [],
            tempCheckedCameraList: [],
            // 监控列表
            cameraList: [],
            newCameraClub:{
                cameraClubName:"",
                cameraNote:"",
            },
            queryCameraParams: {
              groupId: -1,
              search: "",
              untreatedCount: -1,
            }
        }
    },
    created(){
        this.$vc.on(this.$route.path, "cameraManage","addCameraClub", async params =>{
            this.addCameraClubVisible = params.visible;
            if (params.id) {
              const {id, name, cameraCount, comment} = params;
              this.originCameraGroupId = id;
              this.newCameraClub = {
                cameraClubName: name,
                cameraNote: comment,
              }
              this.checkedCameraList = await this.queryCamera({groupId: id, search: '', untreatedCount: -1});
            } else {
                this.cameraList = [];
                this.newCameraClub ={
                    cameraClubName:"",
                        cameraNote:"",
                };
                this.queryCameraParams = {
                    groupId: -1,
                    search: "",
                    untreatedCount: -1,
                }
            }
        });
    },
    methods:{
        /**
         * 摄像头全选
         * @param {Object} camera 摄像头信息
         * */
        checkedTempAllCamera(camera) {
          if (this.cameraList.length === this.tempCheckedCameraList.length) {
            this.tempCheckedCameraList = [];
          } else {
            this.tempCheckedCameraList = this.cameraList;
          }
        },
        async searchCamera() {
            this.cameraList = await this.queryCamera();
        },
        // 获取摄像头
        queryCamera(params) {
          if (params === undefined) {
            params = this.queryCameraParams
          }
          return this.$fly.post(queryCameraListUrl, params)
              .then(res => {
                if (res.code !== 0) {
                  return [];
                }
                return res.data.datas || []
              })
        },
        //添加监控组
        addCameraClub(){
            if(this.judgement()){
              let url = addCameraGroupUrl;
              const {cameraNote: comment, cameraClubName: name} = this.newCameraClub;
              const params = {
                cameraCount: this.checkedCameraList.length,
                comment,
                name,
                ids: this.checkedCameraList.map(item => item.id),
              };
              if (this.originCameraGroupId !== null) {
                url = editCameraGroupUrl;
                params.id = this.originCameraGroupId;
              }
                this.$fly.post(url, params)
              .then(res => {
                if (res.code !== 0) {
                  return ;
                }
                this.$vc.emit(this.$route.path, 'cameraManage', 'refresh', 1);
                this.addCameraClubVisible = false;
                this.$vc.toast(`${this.originCameraGroupId !== null ? '修改' : '添加'}监控组成功`);
              })
               /* this.addCameraClubVisible = false;
                this.$vc.toast("添加成功");
                console.log(this.newCameraClub)
                this.newCameraClub = {
                    cameraClubName:"",
                    cameraId:"",
                    cameraNote:"",
                }*/
            }
        },
        /**
         * 删除已选择的监控
         * @param {Number} index 要删除的监控的下标
         * */
        deleteCheckedCamera(index) {
            this.checkedCameraList.splice(index, 1);
        },
        async opeSelectCameraDialog() {
          this.addCameraVisible = true;
          this.tempCheckedCameraList = this.checkedCameraList;
          this.queryCameraParams.search = '';
          this.cameraList = await this.queryCamera();
        },
        addCamera(ss){
          this.checkedCameraList = this.tempCheckedCameraList;
          this.tempCheckedCameraList = [];
          this.addCameraVisible = false;
        },
        judgement(){
            return this.$vc.validate.validate(
                {
                    newCameraClub:this.newCameraClub,
                },
                {
                    "newCameraClub.cameraClubName":[
                        {
                            limit:"required",
                            param:"",
                            errInfo:"请输入监控组名称"
                        }
                    ]
                }
            )
        }
    }
}
</script>

<style lang="stylus" scoped>
.textarea-view
  width 400px
  height 200px
  background #f0f0f0
  border-radius 4px
  padding 10px
  overflow-y auto
  display inline-block
  vertical-align top
  p
    margin-bottom 10px
.tenant-detail-dialog
  .el-dialog__header
    display none
  .preview-tenant
    color #000
    padding 30px
    font-size 24px
    overflow-y auto
    .field
      &:not(:last-of-type)
        margin-bottom 20px
      &-label
        width 120px
        display inline-block
        margin-right 40px
        text-align right
        vertical-align top
      &-content
        display inline-block
        max-width calc(100% - 240px)
  .el-divider__text.is-left
    font-size 24px
</style>
