import { render, staticRenderFns } from "./cameraManage.vue?vue&type=template&id=532e60b7&scoped=true"
import script from "./cameraManage.vue?vue&type=script&lang=js"
export * from "./cameraManage.vue?vue&type=script&lang=js"
import style0 from "./cameraManage.vue?vue&type=style&index=0&id=532e60b7&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_cache-loader@4.1.0_css-loader@3.6.0_lodash@4.17.21_vue-template-compiler@2.7.16_webpack@4.47.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "532e60b7",
  null
  
)

export default component.exports