<template>
    <CSDialog
            class="tenant-detail-dialog"
            dialog-title="修改监控"
            dialog-width="670px"
            :dialog-visible="changeCameraVisible"
            @onClose="changeCameraVisible = false"
            @onConfirm="commitCameraConfig()"
    >
        <template
                v-slot:dialog-content
        >
            <div class="preview-tenant">
                <div class="field">
                    <span class="field-label">第三方监控</span>
                    <div class="field-content">
                        <CSSelect iWidth="36px" height="40px">
                            <select v-model="newCameraInfo.brand" style="width:385px;">
                                <option value="">&nbsp;请选择</option>
                                <option
                                        v-for="camera in cameraModelList"
                                        :key="camera.id"
                                        :value="camera.id"
                                >
                                    &nbsp;{{camera.name}}
                                </option>
                            </select>
                        </CSSelect>
                    </div>
                </div>
                <div class="field">
                    <span class="field-label">型号</span>
                    <div class="field-content">
                        <input
                                type="text"
                                style="
                              width:397px;
                              height:40px;
                              padding:0px 10px;
                              border:1px solid #979797;
                              border-radius:4px;
                          "
                                placeholder="请输入"
                                v-model="newCameraInfo.type"
                        />
                    </div>
                </div>
                <div class="field" v-if="newCameraInfo.brand === 2">
                    <span class="field-label">事件类型</span>
                    <div class="field-content">
                        <CSRadio :items="cameraEventType" v-model="newCameraInfo.eventType"/>
                    </div>
                </div>
                <div class="field">
                    <span class="field-label">序列号</span>
                    <div class="field-content">
                        <input
                                type="text"
                                style="
                              width:397px;
                              height:40px;
                              padding:0px 10px;
                              border:1px solid #979797;
                              border-radius:4px;
                          "
                                placeholder="请输入"
                                v-model="newCameraInfo.sn"
                        />
                    </div>
                </div>
                <div class="field">
                    <span class="field-label">用户名</span>
                    <div class="field-content">
                        <input
                                type="text"
                                style="
                              width:397px;
                              height:40px;
                              padding:0px 10px;
                              border:1px solid #979797;
                              border-radius:4px;
                          "
                                placeholder="请输入"
                                v-model="newCameraInfo.user"
                        />
                    </div>
                </div>
                <div class="field">
                    <span class="field-label">密码</span>
                    <div class="field-content">
                        <input
                                type="text"
                                style="
                              width:397px;
                              height:40px;
                              padding:0px 10px;
                              border:1px solid #979797;
                              border-radius:4px;
                          "
                                placeholder="请输入"
                                v-model="newCameraInfo.password"
                        />
                    </div>
                </div>
                <div class="field">
                    <span class="field-label">内网IP</span>
                    <div class="field-content">
                        <input
                                type="text"
                                style="
                              width:397px;
                              height:40px;
                              padding:0px 10px;
                              border:1px solid #979797;
                              border-radius:4px;
                          "
                                placeholder="请输入"
                                v-model="newCameraInfo.privateIp"
                        />
                    </div>
                </div>
                <div class="field">
                    <span class="field-label">外网IP</span>
                    <div class="field-content">
                        <input
                                type="text"
                                style="
                                width:397px;
                                height:40px;
                                padding:0px 10px;
                                border:1px solid #979797;
                                border-radius:4px;
                            "
                                placeholder="请输入"
                                v-model="newCameraInfo.publicIp"
                        />
                    </div>
                </div>
                <div class="field">
                    <div class="field-label">外部TCP端口</div>
                    <div class="field-content">
                        <input
                                type="text"
                                style="
                          width:397px;
                          height:40px;
                          padding:0px 10px;
                          border:1px solid #979797;
                          border-radius:4px;
                      "
                                placeholder="请输入"
                                v-model="newCameraInfo.externalTcpPort"
                        />
                    </div>
                </div>
                <div class="field">
                    <div class="field-label">外部RTSP端口</div>
                    <div class="field-content">
                        <input
                                type="text"
                                style="
                          width:397px;
                          height:40px;
                          padding:0px 10px;
                          border:1px solid #979797;
                          border-radius:4px;
                      "
                                placeholder="请输入"
                                v-model="newCameraInfo.externalRtspPort"
                        />
                    </div>
                </div>

                <div class="field">
                    <span class="field-label">监控编号</span>
                    <div class="field-content">
                        <input
                                type="text"
                                style="
                            width:397px;
                            height:40px;
                            padding:0px 10px;
                            border:1px solid #979797;
                            border-radius:4px;
                        "
                                placeholder="请输入"
                                maxlength="20"
                                v-model="newCameraInfo.name"
                        />
                    </div>
                </div>
                <div class="field">
                    <span class="field-label">监控位置</span>
                    <div class="field-content">
                        <ChooseLocation mission="camera" @changeLocation="changeLocation"
                                        :defaultLocationName="newCameraInfo.location"/>
                    </div>
                </div>

                <div class="field">
                    <span class="field-label">识别方案</span>
                    <div class="field-content">
                        <CSSelect iWidth="36px" height="40px">
                            <select v-model="newCameraInfo.boxId" style="width:385px;">
                                <option
                                        v-for="camera in recognitionType"
                                        :key="camera.boxId"
                                        :value="camera.boxId"
                                >
                                    &nbsp;{{camera.count != null?camera.boxName +'(已关联'+camera.count +'个监控)':camera.boxName}}
                                </option>
                            </select>
                        </CSSelect>
                    </div>
                </div>
            </div>
        </template>
    </CSDialog>
</template>

<script>
    import CSDialog from "@/components/common/CSDialog";
    import CSSelect from "@/components/common/CSSelect";
    import ChooseLocation from "@/components/ChooseLocation";
    import {editCameraConfigUrl, queryAiBoxList} from "@/requestUrl";
    import CSRadio from "@/components/common/CSRadio";

    export default {
        components: {
            CSRadio,
            CSDialog,
            CSSelect,
            ChooseLocation,
        },
        data() {
            return {
                cameraEventType: {
                    1: '人脸检测',
                    2: '移动侦测'
                },
                changeCameraVisible: false,
                newCameraInfo: {
                    id: 1,                  //监控id
                    brand: 1, //监控品牌   1大华  2海康
                    eventType: '', //事件类型1.人脸检测2.移动侦测
                    type: "", //监控型号
                    locationId: 0, //位置Id
                    location: "",           //位置
                    name: "", //编号
                    user: "",               //用户名
                    password: "",           //密码
                    externalTcpPort: '',
                    externalRtspPort: '',
                    privateIp: "",          //内网IP
                    publicIp: "", //外网IP
                    sn: "", //序列号,
                    detectionType:"", //识别方案
                    boxId:0

                },
                cameraModelList: [
                    {id: 1, name: "大华"},
                    {id: 2, name: "海康威视"},
                ],
                recognitionType: []
            }
        },
        created() {
            this.$vc.on(this.$route.path, "cameraManage", "changeCameraInfo", data => {
                this.changeCameraVisible = true;
                for (let key in this.newCameraInfo) {
                    if (data.hasOwnProperty(key)) {
                        this.newCameraInfo[key] = data[key];
                    }
                }
            })
            this.queryBox();
        },
        methods: {
            // 提交摄像头配置
            commitCameraConfig() {
                let boxId = this.newCameraInfo.boxId;
                if (boxId === 0){
                    this.newCameraInfo.detectionType = 1
                }else {
                    this.newCameraInfo.detectionType = 2
                }
                if (this.judgement()) {
                    this.$fly.post(editCameraConfigUrl, this.newCameraInfo)
                        .then(res => {
                            if (res.code !== 0) {
                                return;
                            }
                            this.changeCameraVisible = false;
                            this.$vc.emit(this.$route.path, 'cameraManage', 'refresh', 1);
                            this.$vc.toast("修改监控成功")
                        })
                    this.queryBox();
                }
            },
            /**
             * 选择位置
             * @param {Object} location 位置
             * */
            changeLocation(location) {
                console.log("选中的位置"+JSON.stringify(location))
                this.newCameraInfo.locationId = location.id;
                let buildingName = `${location.buildingName}`
                let floor = `${location.floor}`
                let locationInfo = '';
                if (!(buildingName === '') && !(buildingName === 'undefined' && !(buildingName === undefined))) {
                    locationInfo += buildingName;
                }
                if (!(floor === '') && !(floor === undefined) && !(floor === 'undefined')) {
                    locationInfo += floor + '层';
                }
                locationInfo += `${location.specificLocation}`;
                this.newCameraInfo.location = locationInfo
                console.log(JSON.stringify(this.newCameraInfo))
            },
            queryBox() {
                this.$fly.get(queryAiBoxList, {
                    regionCode: this.$vc.getCurrentRegion().code,
                }).then(res => {
                    if (res.code !== 0) {
                        return;
                    } else {
                        this.recognitionType = [{boxName: "请选择"},
                            {boxId: 0, boxName: "虹软"}].concat(res.data);
                    }
                })
            },
            judgement() {
                return this.$vc.validate.validate(
                    {
                        newCameraInfo: this.newCameraInfo,
                    },
                    {
                        "newCameraInfo.brand": [
                            {
                                limit: "required",
                                param: "",
                                errInfo: "请选择第三方监控",
                            }
                        ],
                        "newCameraInfo.type": [
                            {
                                limit: "required",
                                params: "",
                                errInfo: "请输入监控型号",
                            },
                        ],
                        "newCameraInfo.sn": [
                            {
                                limit: "required",
                                params: "",
                                errInfo: "请输入序列号"
                            }
                        ],
                        "newCameraInfo.user": [
                            {
                                limit: "required",
                                params: "",
                                errInfo: "请输入用户名"
                            }
                        ],
                        "newCameraInfo.password": [
                            {
                                limit: "required",
                                params: "",
                                errInfo: "请输入密码"
                            }
                        ],
                        "newCameraInfo.privateIp": [
                            {
                                limit: "required",
                                params: "",
                                errInfo: "请输入内网IP",
                            }
                        ],
                        "newCameraInfo.publicIp": [
                            {
                                limit: "required",
                                params: "",
                                errInfo: "请输入外网IP",
                            }
                        ],
                        "newCameraInfo.name": [
                            {
                                limit: "required",
                                params: "",
                                errInfo: "请输入监控编号",
                            }
                        ]
                    }
                )
            }
        }
    }
</script>

<style lang="stylus" scoped>
    /*.tenant-detail-dialog
      .el-dialog__header
        display none*/
    .preview-tenant
        color #000
        padding 30px
        font-size 24px
        //overflow-y auto

        .field
            &:not(:last-of-type)
                margin-bottom 20px

            &-label
                width 170px
                display inline-block
                margin-right 40px
                text-align right
                vertical-align top

            &-content
                display inline-block
                position relative
                max-width calc(100% - 240px)

    /*.el-divider__text.is-left
      font-size 24px*/
</style>
