    import Cookies from 'js-cookie'

let cookieName = "user_browse";

/**
 * 获取已浏览的监控
 */
const getCookieByName = function (cookieName = "user_browse") {
    let browse = Cookies.get(cookieName);
    return (browse) ? browse : [];
}
/**
 * 添加监控浏览记录
 */
const editCookieByName = function (id) {
    let browse = getCookieByName();
    if (browse !== null && browse.length > 0) {
        browse = browse.split(",");
        if (!browse.includes(id.toString())) {
            browse.push(id)
            Cookies.set(cookieName, browse, {expires: getTime()})
        }
    } else {
        browse.push(id)
        Cookies.set(cookieName, browse, {expires: getTime()})
    }
}
/**
 * 删除cookie
 */
const removeCookieByName = function () {
    Cookies.remove(cookieName)
}

/**
 * 获取当天23:59:59秒
 */
const getTime = function () {
    let curDate = new Date();
    //当前时间戳
    let curTamp = curDate.getTime();
    //当日凌晨的时间戳,减去一毫秒是为了防止后续得到的时间不会达到00:00:00的状态
    let curWeeHours = new Date(curDate.toLocaleDateString()).getTime() - 1;
    //当日已经过去的时间（毫秒）
    let passedTamp = curTamp - curWeeHours;
    //当日剩余时间
    let leftTamp = 24 * 60 * 60 * 1000 - passedTamp;
    let leftTime = new Date();
    leftTime.setTime(leftTamp + curTamp);

    return leftTime;
}

const setCookikeByName = function setUserCookie(name, value, expires) {
    var date = new Date();
    date.setTime(date.getTime() + (expires * 1000));
    document.cookie = name + "="+ escape (value) + ";expires=" + date.toGMTString();
}

export default {
    getCookieByName,
    editCookieByName,
    removeCookieByName,
    setCookikeByName
}
