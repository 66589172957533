<template>
  <div id="cameraManage">
    <CSTabBar
        :checkedTab="tabBarType"
        :tabbar="tabBarList"
        @changeTabBar="changeTabBar"
    ></CSTabBar>
    <div class="filter-panel" style="display: flex; align-items: center">
      <div v-if="tabBarType === 0">
        <input
            v-model="queryParams.search"
            class="cs-input"
            placeholder="搜索监控编号/位置/IP"
            style="width: 200px"
            type="text"
        />
        <div style="display: inline-block">
          <CSSelect>
            <select v-model="queryParams.groupId">
              <option value="-1">监控组不限</option>
              <option
                  v-for="(option, index) in cameraGroupOptions"
                  :key="option.id + 'jian' + index"
                  :value="option.id"
              >
                {{ option.name }}
              </option>
            </select>
          </CSSelect>
        </div>
        <button
            class="btn btn-primary btn-sm"
            style="margin: 0px 30px 15px 10px"
            type="button"
            @click="queryCamera()"
        >
          查询
        </button>
        <!--                <label style="cursor:pointer">
                    <input type="checkbox"   :true-value="1"
                        :false-value="-1" v-model="queryParams.untreatedCount"
                    />
                    <span style="vertical-align:top;margin-left:10px">异常未处理</span>
                </label>-->
        <div style="margin-left: 30px; display: inline-block">
          <label
              style="margin-right: 20px; cursor: pointer"
              @click="turnToTileOrList('title')"
          >
            <div :class="viewType === 1 ? 'class-b' : 'class-a'"></div>
            <span style="position: relative; bottom: 3px">平铺</span>
          </label>
          <label style="cursor: pointer" @click="turnToTileOrList('list')">
            <div :class="viewType === 2 ? 'class-b' : 'class-a'"></div>
            <span style="position: relative; bottom: 3px">列表</span>
          </label>
        </div>
      </div>
      <div v-if="tabBarType === 1">
        <input
            v-model="searchCameraGroupName"
            class="cs-input"
            placeholder="搜索监控组名称"
            style="width: 200px"
            type="text"
        />
        <button
            class="btn btn-primary btn-sm"
            type="button"
            @click="setCameraGroup()"
        >
          查询
        </button>
      </div>
      <div v-if="tabBarType === 2">
        <CSSelect style="width: 220px">
          <el-date-picker
              v-model="searchTotal.startDate"
              :editable="false"
              format="yyyy-MM-dd HH:mm:ss"
              placeholder="请选择开始时间"
              type="datetime"
              value-format="yyyy-MM-dd HH:mm:ss"
          ></el-date-picker>
        </CSSelect>
        <span
            style="
            display: inline-block;
            vertical-align: middle;
            margin: 0px 20px 15px 0px;
            height: 2px;
            width: 20px;
            background-color: #999;
          "
        ></span>
        <CSSelect style="width: 220px">
          <el-date-picker
              v-model="searchTotal.endDate"
              :editable="false"
              format="yyyy-MM-dd HH:mm:ss"
              placeholder="请选择结束时间"
              type="datetime"
              value-format="yyyy-MM-dd HH:mm:ss"
          ></el-date-picker>
        </CSSelect>
        <button
            class="btn btn-primary btn-sm"
            type="button"
            @click="queryTotal"
        >
          查询
        </button>
      </div>
    </div>
    <div v-if="tabBarType === 0" class="result-panel">
      <div v-if="viewType === 1">
        <div
            :style="`position: sticky;top: ${filterHeight}px;z-index: 99;background: #fff;`"
            class="result-panel-header text-right"
        >
          <div
              style="
              float: left;
              line-height: 31px;
              color: #000;
            "
          >
            <span style="margin-right: 30px">监控总数：{{ cameraTotal }}</span>
            <span style="margin-right: 30px">异常未查看总数：{{ nvSum }}</span>
            <!--                        <span>未处理总数：{{untreatedSum}}</span>-->
          </div>
          <div class="sticky-right">
            <label
                v-if="viewType === 1"
                style="
                cursor: pointer;
                vertical-align: top;
                line-height: 31px;
                margin-right: 20px;
                margin-bottom: 0px;
              "
            >
              <input
                  :checked="
                  cameraInfoListOne.length > 0 &&
                  controlCameraList.length === cameraInfoListOne.length
                "
                  style="top: 5px; margin-right: 10px"
                  type="checkbox"
                  @click="titleSelectAllCamera()"
              />
              <span>全选</span>
            </label>
            <button
                class="btn btn-primary btn-sm"
                style="margin: 0px 20px 5px 0"
                type="button"
                @click="batchTurnOnCheck()"
            >
              批量修改检测
            </button>
            <button
                class="btn btn-primary btn-sm"
                style="margin: 0px 20px 5px 0"
                type="button"
                @click="clearCookie()"
            >
              清空看过标记
            </button>
            <button
                class="btn btn-primary btn-sm"
                style="margin-bottom: 5px"
                type="button"
                @click="addCamera('startUp')"
            >
              <svg aria-hidden="true" class="icon">
                <use xlink:href="#icon-menujiahao"></use>
              </svg>
              监控
            </button>
          </div>
        </div>










        <div id="cameraTileView">
          <div
              v-for="camera in cameraInfoListOne"
              :key="camera.id + 'camera'"
              :class="[
              'camera-window',
              'cameraWindow' + camera.id,
              { fullscreen: isFullscreen },
            ]"
              @click="changeCameraVideoPlayStatus(camera.id)"
          >
            .
            <div v-if="!videoPlayers[camera.id]" class="pause-layer">
              <div v-if="getCookie(camera.id)">
                <img alt="" src="../../assets/icon/videoIcon2.png"/>
                继续查看实时画面
              </div>
              <div v-else>
                <img alt="" src="../../assets/icon/videoIcon.png"/>
                点击查看实时画面
              </div>
            </div>
            <video
                :id="`camera_${camera.id}`"
                height="100%"
                src=""
                width="100%"
            />
            <template v-if="!isFullscreen">
              <input
                  v-model="controlCameraList"
                  :value="camera.id"
                  style="position: absolute; top: 15px; right: 15px"
                  type="checkbox"
                  @click.stop
              />
              <span
                  style="
                  position: absolute;
                  top: 15px;
                  left: 15px;
                  color: #fff;
                  font-weight: bold;
                "
              >
                异常未查看数:{{ camera.nvCount }}
              </span>
            </template>

            <span
                style="
                position: absolute;
                top: 15px;
                right: 45px;
                color: #fff;
                font-weight: bold;
              "
            >
              {{ camera.name }} - {{ camera.location }}
            </span>

            <div
                :class="['cameraControlBox' + camera.id, 'camera-control-box']"
                @click.stop
            >
              <div>
                <span @click="fullScreen(camera)">全屏查看</span>
                <span @click="changeCamera(camera.id)">修改监控</span>
                <span @click="deleteCamera(camera.id)">删除监控</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="viewType === 2">
        <CSTable :thead-top="filterHeight">
          <template v-slot:header>
            <div class="table-header-panel">
              <span style="margin-right: 30px">监控总数：{{ cameraTotal }}</span>
              <span style="margin-right: 30px">异常未查看总数：{{ nvSum }}</span>
              <!--                        <span>未处理总数：{{untreatedSum}}</span>-->
              <div class="text-right pull-right" style="margin-top: -6px">
                <button
                    class="btn btn-primary btn-sm"
                    style="margin: 0px 20px"
                    type="button"
                    @click="batchTurnOnCheck()"
                >
                  批量修改检测
                </button>
                <button
                    class="btn btn-primary btn-sm"
                    style="margin-right: 20px"
                    type="button"
                    @click="clearCookie()"
                >
                  清空看过标记
                </button>
                <button
                    class="btn btn-primary btn-sm"
                    type="button"
                    @click="addCamera('startUp')"
                >
                  <svg aria-hidden="true" class="icon">
                    <use xlink:href="#icon-menujiahao"></use>
                  </svg>
                  监控
                </button>
              </div>
            </div>
          </template>
          <template v-slot:thead>
            <tr>
              <th>
                <input
                    :checked="
                    cameraInfoList.length > 0 &&
                    controlCameraList.length === cameraInfoList.length
                  "
                    style="vertical-align: top"
                    type="checkbox"
                    @click="titleSelectAllCamera()"
                />
                <span style="margin-left: 10px; line-height: 20px">全选</span>
              </th>
              <th>添加时间</th>
              <th>监控编号</th>
              <th>事件类型</th>
              <th>位置</th>
              <th>所属监控组</th>
              <th>
                异常未查看
                <!--                                /未处理-->
              </th>
              <th>抓图情况</th>
              <th>操作</th>
            </tr>
          </template>
          <template v-slot:tbody>
            <tr v-for="camera in cameraInfoList" :key="camera.id">
              <td>
                <input
                    v-model="controlCameraList"
                    :value="camera.id"
                    type="checkbox"
                />
              </td>
              <td>{{ camera.createTime }}</td>
              <td style="position: relative">
                <span v-if="getCookie(camera.id)" class="allow-click is-show" @click="checkCameraDetail(camera)">
                  {{ camera.name }}
                </span>
                <span v-else class="allow-click" @click="checkCameraDetail(camera)">
                  {{ camera.name }}
                </span>
              </td>
              <td>
                <span>
                  {{ camera.eventType }}
                </span>
              </td>
              <td>{{ camera.location }}</td>
              <td>
                <template v-if="camera.groupName">
                  {{ camera.groupName.join("、") }}
                </template>
                <template v-else> -</template>
              </td>
              <td>
                {{ camera.nvCount }}
                <!--                                /{{ camera.untreatedCount }}-->
              </td>
              <td>
                <span
                    class="allow-click"
                    @click="lookCaptureFaceDiffLog(camera.id)"
                >查看</span
                >
              </td>
              <td>
                <button
                    aria-expanded="false"
                    aria-haspopup="true"
                    class="btn btn-primary dropdown-toggle"
                    data-toggle="dropdown"
                    type="button"
                >
                  操作
                </button>
                <ul class="dropdown-menu">
                  <li @click="changeCamera(camera.id)">
                    <a style="width: 100%">修改监控</a>
                  </li>
                  <li @click="deleteCamera(camera.id)">
                    <a style="width: 100%">删除监控</a>
                  </li>
                </ul>
              </td>
            </tr>
          </template>
        </CSTable>
        <Pagination componentName="Pagination" name="pagination"></Pagination>
      </div>
    </div>
    <div v-if="tabBarType === 1" class="result-panel">
      <CSTable :thead-top="filterHeight">
        <template v-slot:header>
          <div class="table-header-panel text-right">
            <button
                class="btn btn-primary btn-sm sticky-right"
                type="button"
                @click="addCameraClub()"
            >
              <svg aria-hidden="true" class="icon">
                <use xlink:href="#icon-menujiahao"></use>
              </svg>
              监控组
            </button>
          </div>
        </template>
        <template v-slot:thead>
          <tr>
            <th>添加时间</th>
            <th>监控组名称</th>
            <th>监控</th>
            <th>备注</th>
            <th>操作</th>
          </tr>
        </template>
        <template v-slot:tbody>
          <tr v-for="cameraClub in cameraClubList" :key="cameraClub.id + 'bu'">
            <td>{{ cameraClub.date }}</td>
            <td>{{ cameraClub.name }}</td>
            <td>
              <span
                  v-if="cameraClub.cameraCount"
                  class="allow-click"
                  @click="cameraClubDetail(cameraClub.id)"
              >
                {{ cameraClub.cameraCount }}
              </span>
              <span v-else>
                {{ cameraClub.cameraCount }}
              </span>
            </td>
            <td>
              <span v-if="cameraClub.comment === ''">-</span>
              <span
                  v-else
                  class="allow-click"
                  @click="checkNote(cameraClub.comment)"
              >
                查看
              </span>
            </td>
            <td>
              <button
                  aria-expanded="false"
                  aria-haspopup="true"
                  class="btn btn-primary dropdown-toggle"
                  data-toggle="dropdown"
                  type="button"
              >
                操作
              </button>
              <ul class="dropdown-menu">
                <li @click="changeCameraClub(cameraClub)">
                  <a style="width: 100%">修改监控组</a>
                </li>
                <li @click="deleteCameraClub(cameraClub.id)">
                  <a style="width: 100%">删除监控组</a>
                </li>
              </ul>
            </td>
          </tr>
        </template>
      </CSTable>
      <Pagination componentName="Pagination" name="pagination"></Pagination>
    </div>

    <div v-if="tabBarType === 2" class="result-panel">
      <div style="margin-left: 30px;padding-top: 40px;font-size: 16px;">
        <div>
          <span>私有服务器收到的图片总数：{{ totalObj.privateNumber }}</span>
        </div>
        <div style="margin-top: 10px">
          <span>私有服务器收到的图片总大小(MB)：{{ totalObj.privateFileSize }}</span>
        </div>
      </div>
      <div style="margin-left: 30px;padding-top: 40px;font-size: 16px;">
        <!--        <span>公有云服务器从私有云服务器收到的图片总数：{{ totalObj.ossNumber }}</span>-->
        <!--        <br>-->
        <!--        <span>公有云服务器从私有云服务器收到的图片总大小(MB)：{{ totalObj.ossFileSize }}</span>-->
        <div>
          <span>公有云服务器从私有云服务器收到的图片总数：{{ totalObj.ossNumber }}</span>
        </div>
        <div style="margin-top: 10px">
          <span>公有云服务器从私有云服务器收到的图片总大小(MB)：{{ totalObj.ossFileSize }}</span>
        </div>
      </div>
    </div>


    <ADDCamera/>
    <ADDCameraClub/>
    <ChangeCamera/>
    <CameraControls>
      <template v-if="viewType === 2" v-slot:video>
        <video ref="video" src=""></video>
      </template>
    </CameraControls>
    <BatchTurnOnCheck/>
    <TargetPersonDetail></TargetPersonDetail>
    <TargetPersonVideo></TargetPersonVideo>
    <TargetPersonVideo></TargetPersonVideo>
  </div>
</template>

<script>
import CSTabBar from "@/components/common/CSTabBar";
import CSSelect from "@/components/common/CSSelect";
import Pagination from "@/components/Pagination";
import ADDCamera from "@/components/SecurityCamera/addCamera";
import ChangeCamera from "@/components/SecurityCamera/changeCamera";
import ADDCameraClub from "@/components/SecurityCamera/addCameraClub";
import BatchTurnOnCheck from "@/components/SecurityCamera/batchTurnOnCheck";
import TargetPersonDetail from "@/components/SecurityCamera/targetPersonDetail";
import CameraControls from "@/components/SecurityCamera/cameraControls";
import TargetPersonVideo from "@/components/SecurityCamera/targetPersonVideo";
import CookieUtils from "@/utils/CookieUtils";
import {
  closeRealTimeStreamUrl,
  deleteCameraGroupUrl,
  deleteCameraUrl,
  openRealTimeStreamUrl,
  queryAICameraCountUrl,
  queryCameraConfigUrl,
  queryCameraGroupUrl,
  queryCameraListUrl,
  queryTotalUrl,
} from "@/requestUrl";
import {initStreamVideoPlayer} from "@/utils";
import CSTable from "@/components/common/CSTable";

export default {
  components: {
    CSTable,
    CSTabBar,
    CSSelect,
    Pagination,
    ADDCamera,
    ChangeCamera,
    ADDCameraClub,
    BatchTurnOnCheck,
    TargetPersonDetail,
    CameraControls,
    TargetPersonVideo,
  },
  data() {
    return {
      searchTotal: {
        startDate: dayjs().format("YYYY-MM-DD 00:00:00"),
        endDate: dayjs().format("YYYY-MM-DD 23:59:59")
      },
      queryTimer: null,
      tabBarType: 0,
      filterHeight: 0,
      tabBarList: {
        0: "监控",
        1: "监控组",
        2: "统计",
      },
      searchCameraGroupName: "",
      controlCameraList: [],
      isFullscreen: false,
      visibleList: {
        changeCameraListVisible: false,
      },
      viewType: 1, // 视频展示方式 1:平铺   2:列表
      untreatedSum: 0,
      nvSum: 0,
      cameraTotal: 0,
      /**
       * 摄像头列表
       * @example
       * [{
       "id":1,//监控id
       "createTime":"2021-06-25 10:50", //添加时间
       "name":"相机108", //监控编号
       "location":"",                     //位置
       "groupName":[                      //属于哪个监控组
       "3楼中庭",                      //监控组名称
       "厕所修改后"
       ],
       "nvCount":0,                       //异常未查看数
       "untreatedCount":0                 //异常未处理数量
       }
       ]
       * */
      cameraInfoList: [],
      cameraInfoListOne: [],
      cameraClubList: [],
      cameraGroupOptions: [],
      videoPlayers: {}, // 播放器对象
      titleSelectAll: false,
      queryParams: {
        groupId: -1, //相机组id   查全部传-1
        search: "", //搜索框内容
        untreatedCount: -1, //是否只查看异常未处理   1是  -1所有
      },
      totalObj: {
        privateNumber: 0,
        privateFileSize: 0,
        ossNumber: 0,
        ossFileSize: 0
      },
      pageParams: {
        pageNo: 1,
        pageSize: 10
      }
    };
  },
  updated() {
    this.filterHeight = document.querySelector(".filter-panel").offsetHeight;
  },
  activated() {
    if (this.tabBarType === 0) {
      // this.queryCamera();
    } else if (this.tabBarType === 1) {
      this.setCameraGroup();
    } else {
      this.queryTotal()
    }
  },
  mounted() {
    if (this.viewType == 1) {
      this.queryCamera(null, null);
    } else if (this.tabBarType === 0) {
      this.queryCamera(pageNo);
    } else {
      this.queryTotal()
    }
    this.loadCameraGroup();
    this.$vc.on(this.$route.path, "pagination", "page_event", (pageNo) => {
      this.pageParams.pageNo = pageNo;
      if (this.tabBarType === 1) {
        this.loadCameraGroup(pageNo);
      } else if (this.tabBarType === 0) {
        this.queryCamera(pageNo);
      } else {
        this.queryTotal()
      }
    });
    this.$vc.on(this.$route.path, "cameraManage", "refresh", (tabBarType) => {
      if (this.tabBarType === 0) {
        this.queryCamera();
      } else if (this.tabBarType === 1) {
        this.setCameraGroup();
      } else if (this.tabBarType === 2) {
        this.queryTotal()
      }
    });
    let timer = null;
    document
        .querySelector(".page-content-view")
        .addEventListener("scroll", () => {
          if (timer) {
            clearInterval(timer);
            timer = null;
          }
          timer = setTimeout(() => {
            const cameraView = document.querySelector("#cameraTileView");
            if (
                cameraView.clientHeight + 200 + 100 >=
                document.querySelector(".page-content-view").scrollTop
            ) {
              this.queryCamera(Math.floor(this.cameraInfoList / 4));
              return;
            }
          }, 500);
        });
    window.addEventListener("resize", () => {
      this.filterHeight = document.querySelector(".filter-panel").offsetHeight;
    });
  },
  created() {
    window.addEventListener("keydown", this.CameraSelect);
    this.queryCameraTotal();
  },
  destroyed() {
    window.removeEventListener("keydown", this.CameraSelect);
    document.querySelector(".page-content-view").onscroll = () => {
    };
    for (let playerId of this.videoPlayers) {
      this.closeCameraStream(playerId);
    }
  },
  deactivated() {
    window.removeEventListener("keydown", this.CameraSelect);
  },
  methods: {
    CameraSelect(e) {
      if (e.keyCode == 13) {
        if (this.tabBarType === 0) {
          this.queryCamera();
        } else if (this.tabBarType === 1) {
          this.setCameraGroup();
        } else if (this.tabBarType === 2) {
          this.queryTotal()
        }
      }
    },
    /**
     * 查看监控图片抓拍对比记录
     * @param {Number} id 监控id
     * */
    lookCaptureFaceDiffLog(id) {
      this.$router.push({
        name: "captureFaceDiffLog",
        params: {
          id,
        },
      });
    },
    /**
     * 在可视区域中
     * @param {String} elName 要查询的元素
     * @param {String} parentElName 父级元素
     * */
    isInViewPort(elName, parentElName = ".page-content-view") {
      const el = document.getElementsByClassName(elName)[0];
      const OTHER_HEIGHT = 200;
      const parentView = document.querySelector(parentElName);
      const viewPortHeight = parentView.clientHeight;
      const offsetTop = el.offsetTop;
      const scrollTop = parentView.scrollTop - OTHER_HEIGHT;
      const top = offsetTop - scrollTop;
      return (
          top <= viewPortHeight &&
          (top < 0 ? Math.abs(top) > el.clientHeight : true)
      );
    },
    async setCameraGroup(currentPage = 1, pageSize = 10) {
      const res = await this.getCameraGroupData(
          currentPage,
          pageSize,
          this.searchCameraGroupName
      );
      if (currentPage == 1) {
        this.$vc.emit(this.$route.path, "pagination", "init", {
          total: res?.data?.total || 0,
          pageSize,
          currentPage,
        });
      }
      this.cameraClubList = res?.data?.datas || [];
    },
    getCameraGroupData(pageNo = 1, pageSize = 10, search = "") {
      return this.$fly
          .post(queryCameraGroupUrl, {
            pageNo,
            pageSize,
            search,
          })
          .then((res) => res);
    },
    // 获取监控组列表
    async loadCameraGroup(pageNo = this.pageParams.pageNo, pageSize = 10) {
      const res = await this.getCameraGroupData(pageNo, pageSize);
      this.cameraGroupOptions.push(...(res.data.datas || []));
      this.cameraClubList = res?.data?.datas || [];
    },
    /**
     * 更改视频播放状态
     * @param {Number} id 视频id
     * @param {Boolean} isFullScreen 是否全屏
     * */
    async changeCameraVideoPlayStatus(id, isFullScreen = false) {
      const MAX_VIDEO_PAYER_COUNT = 4;
      const playerIds = Object.keys(this.videoPlayers),
          isReachTheLimit =
              !playerIds.includes(id.toString()) &&
              playerIds.length >= MAX_VIDEO_PAYER_COUNT;
      if (!isFullScreen && isReachTheLimit) {
        this.$vc.message(`最多同时播放${MAX_VIDEO_PAYER_COUNT}个视频`);
        return;
      }
      if (!this.videoPlayers[id]) {
        console.log("开启播放");
        const player = await this.queryCameraStreamUrl(id);
        if (!player) {
          this.$vc.message("打开实时视频失败");
          return;
        }
        CookieUtils.editCookieByName(id)
        // this.videoPlayers[id] = player;
        player.play();
        player.fullScreen = isReachTheLimit;
        this.$set(this.videoPlayers, id, player);
      } else {
        this.closeCameraStream(id);
        this.videoPlayers[id].pause();
        this.$set(this.videoPlayers, id, null);
        delete this.videoPlayers[id];
        console.log("暂停播放");
      }
    },
    // 获取摄像头
    async queryCamera(pageNo = this.pageParams.pageNo, pageSize = 10) {
      if (this.tabBarType !== 1 && this.viewType === 1) {
        pageSize = null;
      }
      await this.$fly
          .post(queryCameraListUrl, {
            ...this.queryParams,
            pageNo, //页数
            pageSize, //页面大小
          })
          .then(async (res) => {
            if (res.code !== 0) {
              return;
            }
            if (pageNo === 1) {
              this.$vc.emit(this.$route.path, "pagination", "init", {
                total: res?.data?.total || 0,
                pageSize: pageSize,
                currentPage: pageNo,
              });
            }
            console.log("摄像头==", res.data.datas);
            let data = res.data.datas;
            /*for(let i = 0; i < 16; i++) {
                        data.push({...res.data.datas[0], id: res.data.datas[0].id++});
                    }*/
            if (pageSize) {
              this.cameraInfoList = data;
            } else {
              this.cameraInfoListOne = data;
            }

            this.untreatedSum = res.data?.untreatedSum;
            this.nvSum = res.data?.nvSum;
          });
    },
    // 查询统计
    queryTotal() {
      this.$fly.get(queryTotalUrl, {
        regionCode: this.$vc.getCurrentRegion().code,
        startTime: this.searchTotal.startDate,
        endTime: this.searchTotal.endDate
      }).then(async (res) => {
        console.log("queryTotal", res.data)
        this.totalObj.privateNumber = res.data.privateNumber == null ? 0 : res.data.privateNumber;
        this.totalObj.privateFileSize = res.data.privateFileSize == null ? 0 : res.data.privateFileSize;
        this.totalObj.ossNumber = res.data.ossNumber == null ? 0 : res.data.ossNumber;
        this.totalObj.ossFileSize = res.data.ossFileSize == null ? 0 : res.data.ossFileSize;
      })
    },
    /**
     * 获取视频流地址
     * @param {Number} id 监控id
     * @param {String} videoEl 显示监控的标签id或者元素
     * */
    async queryCameraStreamUrl(id, videoEl) {
      const data = await this.$fly.get(openRealTimeStreamUrl, {id});
      const cameraStreamUrl = data.data;
      try {
        console.log("开始调用初始化监控函数", id);
        const player = await initStreamVideoPlayer(
            (err) => {
              console.log(err, "监控的错误信息");
            },
            videoEl ? videoEl : `camera_${id}`,
            {
              url: cameraStreamUrl,
            }
        );
        return player;
      } catch (err) {
        console.log(err, "初始化监控错误信息");
      }
    },
    /**
     * 关闭视频流
     * @param {Number} id 监控id
     * */
    closeCameraStream(id) {
      try {
        this.videoPlayers[id].pause();
      } catch (err) {
        console.log(err, "暂停视频错误");
      }
      return this.$fly
          .get(closeRealTimeStreamUrl, {id})
          .then((res) => res.data || "");
    },
    changeTabBar(index) {
      this.tabBarType = index;
      if (index === 0) {
        this.queryCamera();
      } else if (index === 1) {
        this.setCameraGroup();
        // this.loadCameraGroup();
      } else {
        this.queryTotal()
      }
    },
    turnToTileOrList(act) {
      this.controlCameraList = []
      clearTimeout(this.queryTimer);
      this.queryTimer = setTimeout(() => {
        if (act === "title") {
          this.viewType = 1;
          this.queryCamera(null, null);
        } else if (act === "list") {
          this.viewType = 2;
          this.queryCamera();
        }
      });
    },
    // 打开批量检测状态修改
    batchTurnOnCheck() {
      if (JSON.stringify(this.controlCameraList) === "[]") {
        this.$vc.toast("请勾选监控");
      } else {
        this.$vc.emit(this.$route.path, "cameraManage", "batchTurnOnCheck", {
          visible: true,
          ids: this.controlCameraList,
        });
      }
    },
    addCamera() {
      this.$vc.emit(this.$route.path, "cameraManage", "addCamera", true);
    },
    /**
     * 列表查看全屏监控
     * @param {Object} camera 监控信息
     * */
    checkCameraDetail(camera) {
      console.log(this.$refs.video, "监控摄像头");
      this.queryCameraStreamUrl(camera.id, this.$refs.video);
      this.fullScreen(camera, "requestFullscreen", this.$refs.video);
    },
    /**
     * 获取摄像头的配置信息
     * @param {Number} id 摄像头id
     * */
    queryCameraConfigInfo(id) {
      return this.$fly
          .get(queryCameraConfigUrl, {
            id,
          })
          .then((res) => {
            if (res.code !== 0) {
              return null;
            }
            return res.data;
          });
    },
    /**
     * 修改监控
     * @param {Number} id 摄像头id
     * */
    async changeCamera(id) {
      const configInfo = await this.queryCameraConfigInfo(id);
      if (!configInfo) {
        return;
      }
      this.$vc.emit(
          this.$route.path,
          "cameraManage",
          "changeCameraInfo",
          configInfo
      );
    },
    async fullScreen(target, act = "requestFullscreen", el) {
      this.$vc.emit("cameraManageLeft", true)
      const cameraWindow =
          el || document.querySelector(".cameraWindow" + target?.id);
      if (act === "requestFullscreen") {
        if (!this.videoPlayers[target.id]) {
          this.changeCameraVideoPlayStatus(target.id, true);
        }
        document.body.requestFullscreen();
        this.$vc.emit(this.$route.path, "cameraManage", "cameraControls", {
          target,
          visible: true,
        });
      }
      if (!cameraWindow) {
        return;
      }
      document.body.onfullscreenchange = () => {
        if (document.fullscreenElement) {
          cameraWindow.style.position = "fixed";
          cameraWindow.style.top = "0";
          cameraWindow.style.left = "0";
          cameraWindow.style.right = "0";
          cameraWindow.style.bottom = "0";
          cameraWindow.style.width = "100vw";
          cameraWindow.style.height = "100vh";
          cameraWindow.style.background = "rgba(0, 0, 0, 1)";
          cameraWindow.style.zIndex = "2004";
          this.isFullscreen = true;
        } else {
          if (this.videoPlayers[target.id]?.fullScreen) {
            this.changeCameraVideoPlayStatus(target.id);
          }
          this.queryCamera();
          this.isFullscreen = false;
          cameraWindow.style.position = "relative";
          cameraWindow.style.width = "540px";
          cameraWindow.style.height = "340px";
          cameraWindow.style.zIndex = "1";
          cameraWindow.style.background = "rgba(0, 0, 0, .2)";
          this.$vc.emit(this.$route.path, "cameraManage", "cameraControls", {
            target: null,
            visible: false,
          });
        }
      };
    },
    /**
     * 删除摄像头
     * @param {Number} id 监控id
     * */
    deleteCamera(id) {
      this.$CSDialog.warning({
        messageHtml: "<span>确定删除吗?</span>",
        onConfirm: () => {
          this.$fly.get(deleteCameraUrl, {id}).then((res) => {
            if (res.code !== 0) {
              return;
            }
            this.$vc.toast("删除成功");
            this.$CSDialog.instance.closeDialog();
            this.queryCamera();
          });
        },
      });
    },
    titleSelectAllCamera() {
      if (this.viewType === 1) {
        if (this.controlCameraList.length !== this.cameraInfoListOne.length) {
          this.controlCameraList = this.cameraInfoListOne.map((item) => item.id);
        } else {
          this.controlCameraList = [];
        }
      } else if (this.viewType === 2) {
        if (this.controlCameraList.length !== this.cameraInfoList.length) {
          this.controlCameraList = this.cameraInfoList.map((item) => item.id);
        } else {
          this.controlCameraList = [];
        }
      }
    },
    addCameraClub() {
      this.$vc.emit(this.$route.path, "cameraManage", "addCameraClub", {
        visible: true,
      });
    },
    /**
     * 查询监控组
     * @param {Number} id 监控组id
     * */
    cameraClubDetail(id) {
      this.$router.push({
        name: "cameraClubDetail",
        params: {
          id,
        },
      });
    },
    /**
     * 修改监控组
     * @param {*} cameraGroup 监控组信息
     * */
    changeCameraClub(cameraGroup) {
      this.$vc.emit(this.$route.path, "cameraManage", "addCameraClub", {
        visible: true,
        ...cameraGroup,
      });
    },
    /**
     * 删除监控组
     * @param {Number} id
     * */
    deleteCameraClub(id) {
      this.$CSDialog.warning({
        title: "提示",
        messageHtml: "<span>确定删除吗?</span>",
        onConfirm: () => {
          this.$fly
              .get(deleteCameraGroupUrl, {
                id,
              })
              .then((res) => {
                if (res.code !== 0) {
                  return;
                }
                this.$CSDialog.instance.closeDialog();
                this.$vc.toast("删除成功");
                this.setCameraGroup();
              });
        },
      });
    },
    /**
     * 查看监控组备注
     * @param {Object} comment
     * */
    checkNote(comment) {
      this.$CSDialog.alert({
        title: "备注",
        messageHtml: `<span>${comment}</span>`,
        width: "450px",
      });
    },
    async queryCameraTotal() {
      await this.$fly
          .get(queryAICameraCountUrl, {
            regionCode: this.$vc.getCurrentRegion().code
          })
          .then((res) => {
            if (res.code !== 0) {
              return;
            }
            this.cameraTotal = res.data;
          });
    },
    clearCookie() {
      CookieUtils.removeCookieByName();
      this.queryCamera()
      this.$vc.toast("清空完成");
    },
    getCookie(id) {
      let browse = CookieUtils.getCookieByName();
      if (browse !== null && browse.length > 0) {
        browse = browse.split(",");
        if (browse.includes(id.toString())) {
          return true;
        }
      }
      return false;
    }
  },
};
</script>

<style lang="stylus" scoped>
#cameraTileView {
  padding: 15px 10px 15px 30px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

  .camera-window {
    position: relative;
    width: 540px;
    height: 340px;
    overflow: hidden;
    margin: 0 20px 15px 0;
    background: black;

    .pause-layer {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
      font-size: 30px;
      text-align: center;
      color: #fff;

      div {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        img {
          width: 152px;
          display: block;
          margin: 0 auto;
          margin-bottom: 40px;
        }
      }
    }

    .camera-control-box {
      position: absolute;
      bottom: -40px;
      width: 100%;
      background: rgba(0, 0, 0, 0.7);
      transition: bottom 0.4s;
      display: none;

      & > div {
        padding: 0 110px;
        display: flex;
        height: 40px;
        justify-content: space-between;
        align-items: center;
        color: #FFFFFF;

        span {
          cursor: pointer;
        }
      }
    }

    &:hover {
      .camera-control-box {
        bottom: 0;
        display: block;
      }
    }

    &.fullscreen:hover {
      .camera-control-box {
        display: none;
      }
    }
  }
}

.tenant-detail-dialog {
  .el-dialog__header {
    display: none;
  }

  .preview-tenant {
    color: #000;
    padding: 30px;
    font-size: 24px;
    overflow-y: auto;

    .field {
      &-label {
        width: 120px;
        display: inline-block;
        margin-right: 40px;
        text-align: right;
        vertical-align: top;
      }

      &-content {
        display: inline-block;
        max-width: calc(100% - 240px);
      }
    }
  }

  .el-divider__text.is-left {
    font-size: 24px;
  }
}

.class-a {
  position: relative;
  margin-right: 5px;
  top: 2px;
  left: 0;
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url('../../assets/icon5.png');
}

.class-b {
  position: relative;
  margin-right: 5px;
  top: 2px;
  left: 0;
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url('../../assets/icon3.png');
}

.selectLocation {
  width: 300px;
}

@media screen and (max-width: 1370px) {
  #cameraTileView {
    .camera-window {
      margin-right: 0;

      &:not(:nth-of-type(even)) {
        margin-right: 20px;
      }
    }
  }
}

.is-show
  position relative

.is-show::after
  border-radius: 10px;
  content: "已看过";
  position: absolute;
  width 42px
  background: rgba(255, 235, 201, 1);
  top -10px
  color #FF9F00
  font-size 12px
  opacity: 1;
  pointer-events none


</style>
